
  import { Component, Mixins } from "vue-property-decorator";
  import { bus } from "@/main";
  import HasNotaryModal from "./has-notary-modal";
  import { Mandate } from "@/store/modules/mandate.store";

  @Component
  export default class NotaryAddModal extends Mixins(HasNotaryModal) {
    mounted() {
      bus.$off("show-add-notary");
      bus.$on("show-add-notary", async (type: "SELLER" | "BUYER", subject: Mandate | Bid | Activity) => {
        this.getNotaryOptions();

        this.notaryType = type;

        this.subject = subject;

        this.show = true;
      });
    }
  }
